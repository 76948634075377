import { render, staticRenderFns } from "./Manifest.vue?vue&type=template&id=3dc49040&scoped=true&"
import script from "./Manifest.vue?vue&type=script&lang=js&"
export * from "./Manifest.vue?vue&type=script&lang=js&"
import style0 from "./Manifest.vue?vue&type=style&index=0&id=3dc49040&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc49040",
  null
  
)

export default component.exports
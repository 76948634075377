<template>
  <div class="notificationdelay">
    <notification-delay-list />
  </div>
</template>

<script>
import NotificationDelayList from '@/components/NotificationDelayList.vue'

export default {
  components: {
    NotificationDelayList
  },
}
</script>
